<template>
    <article-case v-if="articleID !== ''" :articleID="articleID"></article-case>
</template>

<script>
// @ is an alias to /src
import ArticleCase from '../components/layouts/ArticleCase.vue'

export default {
    name: 'Article',
    components: {
        ArticleCase
    },
    data() {
        return {
            articleID: ''
        }
    },
    mounted() {
        this.articleID = this.$route.params.articleID
    }
}
</script>

<style scoped>

</style>
