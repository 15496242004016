<template>
    <div class="top-spacer"></div>
    <div class="page-wrapper" v-if="loaded">
        <meta-data :Title="metaTitle" :Description="metaDescription" :Auhtor="metaAuthor" />
        <div class="top-image">
          <img :src="imageUrl" :alt="imageAlt" />
        </div>
        <div class="title"><h1>{{title}}</h1></div>
        <div class="spacer"></div>
        <div class="content">
          <Markdown :source="content" />
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios';
import qs from 'qs';
import Markdown from 'vue3-markdown-it'
import MetaData from '../shared/MetaData.vue'

export default {
    name: 'ArticleCase',
    components: {
      Markdown,
      MetaData
    },
    props: {
      caseID: String,
      articleID: String
    },
    data() {
      return {
        title: "Titlen på case eller artiklen",
        content: "Dette vil være den lange liste med selve indholdet",
        imageUrl: "",
        metaImageUrl: "",
        loaded: false,
        metaTitle: 'Artikler og cases',
        metaDescription: 'Læs vores mange guides til, hvordan i kan få mest ud af outdoor annoncering.',
        metaAuthor: 'Reklameskærm.dk',
        imageAlt: ''
      }
    },
    mounted() {
      if(this.caseID === undefined && this.articleID === undefined) {
        this.$router.push('');
      }
      
      if(this.caseID !== undefined) {
        this.getCase(this.caseID);
      }
      else if (this.articleID !== undefined) {
        this.getArticle(this.articleID);
      }
    },
    methods: {
      getCase(caseID) {
        var query = qs.stringify({
              populate: '*',
          });
          axios.get(`${process.env.VUE_APP_STRAPI_API_URL}cases?${query}&filters[HumanizedLink][$eq]=${caseID}`, )
          .then(response => {
              this.setViewdata(response.data.data[0].attributes);
          })
      },
      getArticle(articleID) {
        var query = qs.stringify({
              populate: '*'
          });
          axios.get(`${process.env.VUE_APP_STRAPI_API_URL}articles?${query}&filters[HumanizedLink][$eq]=${articleID}`, )
          .then(response => {
              this.setViewdata(response.data.data[0].attributes);
          })
      },
      setViewdata(strapiModel) {
        this.title = strapiModel.Name;
        this.content = strapiModel.Content;
        this.imageUrl = strapiModel.Media.data[0].attributes.formats.large.url;
        this.imageAlt = strapiModel.Media.data[0].attributes.alternativeText;
        this.metaImageUrl = strapiModel.Media.data[0].attributes.formats.small.url;
        this.metaTitle = strapiModel.metaTitle;
        this.metaDescription = strapiModel.metaDescription;
        this.metaAuthor = strapiModel.metaAuthor;
        this.loaded = true;
      }
    }
}
</script>

<style scoped>
@media only screen and (min-width: 768px) {
    /* For mobile phones: */
    .page-wrapper {
      border-top: 1px solid rgb(204 204 204);
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .page-wrapper {
      width: 100% !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .page-wrapper h2 {
      padding-left: 20px;
    }
    .top-spacer {
      height: 50px !important;
    }

    .spacer {
      border-bottom: 3px solid #9433ff;
      width: 3.4rem;
      margin-left: 20px;
    }

    .top-image img {
      max-height: 400px;
      object-fit: cover;
      border-radius: 0px !important;
    }

    .title h1 {
      width: unset !important;
      margin-top: 0px !important;
      text-align: left !important;
      font-size: 24px !important;
      padding: 20px 20px 10px 20px !important;
      border-bottom: none !important;
      line-height: 2rem !important;
  }

  .content h1 {
    font-size: 2px !important;
    line-height: 2rem !important;
  }
}

@media only screen and (max-width: 1200px) {
    .content {
      width: 80% !important;
    }
}

.top-spacer {
  width: 100%;
  height: 80px;
}

.page-wrapper {
  line-height: 25px;
  width: 100%;
  max-width: 1488px;
  margin: 0 auto;
  padding-left: 20px;
  padding-bottom: 40px;
}

.top-image {
  width: 100%;
  margin-top: 20px;
  text-align: center;
}

.top-image img {
  max-height: 400px;
  object-fit: cover;
  border-radius: 12px;
}

.title h1 {
  width: 100%;
  max-width: 47rem;
  margin: 0 auto;
  margin-top: 50px;
  text-align: center;
  font-size: 42px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(212, 212, 212);
  line-height: 3rem;
}

.content {
  width: 50%;
  margin: 0 auto;
  margin-top: 50px;
  object-fit: contain;
  text-align: left;
  opacity: 0.7;
  font-size: 18px;
}

.content p {
  margin-block-start: -18px;
}

</style>
